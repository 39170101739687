// Px to rem
@mixin rem($size) {
  $remSize: $size / 16;
  font-size: #{$remSize}rem;
}

/**
 * Computes a CSS calc function that betweens a value from
 * A to B over viewport-width A to viewport-width B.
 * Requires a media query to cap the value at B.
 */

@function between($to, $from, $toWidth, $fromWidth) {
  $slope: ($to - $from) / ($toWidth - $fromWidth);
  $base: $from - $slope * $fromWidth;

  @return calc(#{$base} + #{100vw * $slope});
}



@mixin fs($size) {
  $baseMax: 1920;
  $multiplier: 2;
  $max: $baseMax * $multiplier * 1px;
  font-size: between(0px, $size, 0px, $max);
}

// Font
// Roboto: 300, 400, 500, 700
@mixin fontrb($fontsize: 32, $fontweight: 400, $setFontSize: true) {
  font-family: 'Roboto', sans-serif;
  font-weight: $fontweight;
  @if $setFontSize {
    @include rem($fontsize/2);
  }
}

// Size mixin
@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}

@function is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@function unpack($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  } @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  } @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  } @else {
    @return $shorthand;
  }
}

// Position
@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $coordinates: unpack($coordinates);

  $offsets: (
          top:    nth($coordinates, 1),
          right:  nth($coordinates, 2),
          bottom: nth($coordinates, 3),
          left:   nth($coordinates, 4)
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if is-length($value) {
      #{$offset}: $value;
    }
  }
}

// Date style
@mixin date() {
  @include fontrb(29, 400);
  color: $lblue;
}