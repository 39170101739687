// login styles


.login-page--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-height: 0;
  z-index: 2;
  @include size(100%);
  position: relative;
}

.login--content-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30.4vw;
  flex: 0 0 30.4vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  min-width: 300px;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transition: transform .6s ease;

  .border-left {
    @include position(absolute, null null 0 0);
    @include size(10px 0);
    background: $lblue; /* Old browsers */
    background: -moz-linear-gradient(top, $lblue 0%, #074690 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $lblue 0%,#074690 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $lblue 0%,#074690 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    transition: height .6s ease;
    &.start-animation {
      height: 100%;
    }
  }

  &.is-hidden {
    transform: translate3d(-100%, 0, 0);
  }
}

.login--spacer {
  width: 100%;
}

.login--content {
  padding: 20vh 20px 0;
  height: 100%;
  position: relative;
  width: 100%;
  max-width: 370px;
  margin: 0 auto;
  img {
    @include size(100% auto);
    opacity: 0;
    transform: translateY(20px);
    backface-visibility: hidden;
    display: block;
    transition: opacity .6s, transform .6s;
    &.start-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .form-info--text {
    @include position(absolute, null null 2vh 50%);
    width: 100%;
    max-width: 300px;
    transform: translate(-50%, 10px);
    @include fontrb(27, 400);
    color: #535353;
    line-height: 1.48;
    text-align: center;

    transition: transform .6s ease, opacity .6s;
    opacity: 0;
    &.start-animation {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }
}

/* Login form */
.login-form {
  margin-top: 5.5vh;
  .input-wrap {
    padding: 0 18px;
    margin-bottom: 15px;
    position: relative;
    input {
      padding: 27px 20px 28px 70px;
      border: 0;
      @include fontrb(38, 300);
      color: #000;
      line-height: 20px;
      opacity: 0;
      transition: opacity .4s ease;
      transform: translate3d(0,0,0);
      &:focus, &:active {
        outline: none;
      }

      &:focus {
        + .input-border:before,
        + .input-border:after,
        + .input-border .input-border--hidden {
          width: 100%;
        }
      }

      &.start-animation {
        opacity: 1;
      }
    }

    .input-border {
      @include position(absolute, null null 0 0);
      @include size(0 1px);
      position: relative;
      background: #000;
      transition: width .6s ease;
      &:before {
        @include position(absolute, 0 null null 0);
        @include size(0% 1px);
        background: #018fd4;
        content: '';
        transition: width .2s ease-out;
        z-index: 1;
      }
      &:after {
        @include position(absolute, 2px null null 0);
        @include size(0% 1px);
        background: #018fd4;
        content: '';
        transition: width .2s ease-out;
        z-index: 1;
      }

      &.start-animation {
        width: 100%;
      }
    }

    .input-border--hidden {
      @include size(0 5px);
      @include position(absolute, null null -2px 0);
      border-top: 1px solid #018fd4;
      border-bottom: 1px solid #018fd4;
      transition: width .3s ease-out;
    }
  }

  .input-icon {
    @include position(absolute, 50% null null 18px);
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: opacity .4s ease;
    z-index: 1;
    &.icon-email {
      @include size(40px 27px);
      background-image: url('../images/icon-email.png');
      background-size: 40px 20px;
    }

    &.icon-password {
      @include size(40px 27px);
      background-image: url('../images/icon-password.png');
      background-size: 20px 27px;
    }

    &.start-animation {
      opacity: 1;
    }
  }

  input[type="submit"] {

  }
}

.show-from-bottom {
  transition: transform .6s ease, opacity .6s;
  opacity: 0;
  transform: translateY(10px);
  &.start-animation {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-text--content {
  text-align: center;
  margin-top: 18px;
  .small {
    text-decoration: none;
    @include fontrb(30, 300);
    color: #000;
    &:hover {
      text-decoration: underline;
    }
  }

  #register-link {
    display: block;
    margin-top: 30px;
    @include fontrb(33, 400);
    color: #000;
    text-decoration: none;
    line-height: 1.81;
    span {
      text-decoration: underline;
    }
    &:hover {
      color: $dblue;
    }
  }
}