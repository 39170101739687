// home-content styles

$home-content-grid: (
        columns: 3,
        gutter: 20px,
        media: 1024px
);

.home-content {
  @include grid-container;
  @include grid-collapse($home-content-grid);
  .block-wrap {
    @include grid-column(3, $home-content-grid);
    height: 50%;
    @include grid-media($home-content-grid){
      @include grid-column(1);
      &.double {
        @include grid-column(2, $home-content-grid);
        height: 100%;
      }
    }
  }

  .block {
    width: 100%;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    transform: translate3d(0,0,0);
    backface-visiblity: hidden;
    .block-bg {
      @include position(absolute, null null 0 0);
      @include size(100% 0);
      z-index: 1;
      transform: translate3d(0,20px,0);
      backface-visiblity: hidden;
      will-change: height;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      transition: height 1.2s cubic-bezier(.47,.29,.13,.97), transform .6s;
      &.start-animation {
        height: 100%;
        transform: translate3d(0,0,0);
      }
    }

    .block-content {
      position: absolute;
      top: between(0px, 80px, 0px, 3840px);
      left: between(0px, 80px, 0px, 3840px);
      z-index: 2;
      max-width: 50%;
      height: 100%;
      transform: translate3d(0, 20px, 0);
      backface-visibility: hidden;
      opacity: 0;
      transition: transform 0.8s cubic-bezier(.47,.29,.13,.97), opacity .8s ease;

      &.start-animation {
        transform: translate3d(0, 0px, 0);
        opacity: 1;
      }
      .sub-title {
        @include fontrb(40, 400, false);
        color: $lblue;
        line-height: 1.33;
        @include fs(40);
        opacity: 0;
        transition: opacity .6s ease;
        backface-visibility: hidden;
        &.start-animation {
          opacity: 1;
        }
      }
      h1 {
        @include fontrb(70, 700, false);
        color: #000;
        margin: 10px 0 5px;
        @include fs(70);
        line-height: 1.2;
      }
      p {
        @include fontrb(32, 400, false);
        color: #000;
        line-height: 1.5;
        @include fs(32);
        margin: 0 0 15px;
      }
    }


    &:hover .block-bg {
      transform: scale(1.04);
    }
  }

  .block-wrap.double .block-content {
    max-width: 385px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    h1 {
      @include fs(120);
    }
    p {
      @include fs(40);
    }

  }
}