// chefdirekt-content styles
.chefdirekt-content--container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%;
  .col {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    min-height: 900px;
    width: 50%;
    padding-right: 60px;

    &:last-child {
      padding-right: 0;
    }
  }

  .img-zoom {
    @include size(100%);
    min-height: 82vh;
    position: relative;
    overflow: hidden;
    .zoom-bg {
      @include size(100%);
      @include position(absolute, 0 null null 0);
      background-repeat: no-repeat;
      background-size: cover;
      backface-visibility: hidden;
      transform-origin: center center;
      animation: zoom 100s ease;
      animation-fill-mode: forwards;
    }
  }

  h1 {
    @include fontrb(85, 400);
    color: #000;
    margin-bottom: 10px;
    margin-top: 80px;
  }

  h2 {
    @include fontrb(55, 400);
    color: #0287cd;
    margin-top: 10px;
  }

}

@keyframes zoom {
  0% {
    transform: scale3d(1,1,1);
  }

  100% {
    transform: scale3d(2,2,1);
  }
}

.chef-form--wrap {
  margin-top: 50px;
  position: relative;
}

.chef-form--container {
  @include position(absolute, 0 0 null -100px);
  background: rgba(255,202,42,.97);
  padding: 55px 120px;
}

.chefForm {
  .input-wrap {
    position: relative;
    margin-bottom: 27px;
    input, textarea {
      width: 100%;
      border-radius: 63px;
      background: #ffd558;
      transition: background .3s ease;
      border: 0;
      padding: 21px 20px 20px 120px;
      @include fontrb(46, 400);
      color: #000;
      &:active, &:focus {
        background: #fff;
        outline: none;
      }
    }

    textarea {
      resize: none;
      border-radius: 10px;
      max-height: 240px;
    }

    label {
      @include fontrb(36, 300);
      color: #6d6d6d;
      @include position(absolute, 32px null null 40px);
      transform: translateY(-50%);
    }
  }

  button.submit {
    width: 100%;
    text-align: center;
    position: relative;
    background: transparent;
    border: 2px solid #fff;
    padding: 21px 0 20px;
    cursor: pointer;
    border-radius: 63px;

    .text {
      @include fontrb(38, 500);
      color: #fff;
      position: relative;
      z-index: 2;
      transition: color .3s ease;
    }

    .bg {
      @include position(absolute, 0 0 0 0);
      background: #fff;
      border-radius: 63px;
      z-index: 0;
      opacity: 0;
      transition: all .2s;
    }

    &:focus, &:hover, &:active {
      outline: none;
      .text {
        color: $yellow;
      }
      .bg {
        opacity: 1;
      }
    }

    &:active {
      .bg {
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
      }
    }
  }

  .open-question {
    display: table;
    margin-bottom: 35px;
    span {
      @include fontrb(34, 400);
      color: #1e1e1e;
      line-height: 1.39;
      display: table-cell;
      width: 50%;
      vertical-align: middle;
    }
    .type-choose {
      display: table-cell;
      width: 50%;
      text-align: right;
      vertical-align: middle;
      .type-button {
        display: inline-block;
        margin-left: 15px;
        background: transparent;
        border: 1px solid rgba(84,84,84,.5);
        color: #6c6c6c;
        @include fontrb(30, 400);
        text-align: center;
        border-radius: 63px;
        padding: 9px 0 8px;
        width: 100px;
        cursor: pointer;
        &:active, &:focus, &:hover, &.is-active {
          outline: none;
          background: rgb(84,84,84);
          color: #fff;
        }
      }
    }
  }
}