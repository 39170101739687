// modal styles

/* Effect 11: Super scaled */

.md-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  min-width: 320px;
  height: auto;
  z-index: 2000;
  visibility: hidden;
  overflow: visible;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(143,27,15,0.8);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

.md-effect .md-content {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect .md-content {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.modal-bg {
  @include position(fixed, 0 0 null null);
  @include size(100%);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility 0s .3s;
  background: rgba(0,0,0,.9);
  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s;
  }
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  @include position(absolute, 10px -75px null null);
  &:focus, &:active {
    outline: none;
  }
}
