// rueckmeldung-content styles
.rueckmeldung-content {
  overflow: hidden;
}
.rueckmeldung--container {
  h1 {
    text-align: center;
  }
}

.search-form {
  width: 100%;
  max-width: 920px;
  padding: 0 20px;
  margin: 0 auto;
  padding-top: 20px;
}

.searchForm {
  .input-wrapper {
    overflow: hidden;
    position: relative;
  }
  input {
    width: 100%;
    @include fontrb(46, 400);
    color: #000;
    padding: 21px 57px 20px;
    background: #fff;
    border-radius: 65px;
    border: 0;
    &:active, &:focus {
      outline: none;
    }
  }

  button {
    margin-top: 0;
    max-width: 190px;
    @include position(absolute, 6px 6px null null);
    padding: 17px 0 17px;
    i {
      @include position(absolute, 50% 24px null null);
      transform: translateY(-50%);
      @include rem(30);
    }
  }
}

.questions-wrap {
  margin-top: 40px;

  .col {
    @include grid-column(1 of 2);
  }
}

.all-question--article {
  padding: 35px 60px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: transparent;
  transition: background .3s ease;

  .all-question--content-container {
    border-bottom: 2px solid #d5d5d5;
    transition: border-color .2s ease;
  }

  &.is-opened {
    background: #fff;
    .all-question--content-container {
      border-color: transparent;
    }
  }
}