// lagebericht-content styles

$lagebericht-content-grid: (
        columns: 3,
        gutter: 20px,
        media: 1024px
);

.lagebericht-content {

  .lagebericht-content--container {
    @include grid-container;
    @include grid-collapse($lagebericht-content-grid);
    margin-top: 75px;
    .lagebericht-item {
      @include grid-column(3, $lagebericht-content-grid);
      @include grid-media($lagebericht-content-grid) {
        @include grid-column(1);
      }

      margin-bottom: 65px;

      figure {
        margin: 0 0 25px;
        width: 100%;
        position: relative;
        overflow: hidden;
        img {
          @include size(100% auto);
          visibility: hidden;
        }

        .figure-bg {
          @include position(absolute, 0 null null 0);
          @include size(100%);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          transform: scale(1);
          transition: transform .6s ease;
        }

        &:hover {
          .figure-bg {
            transform: scale(1.04);
          }
        }
      }
    }
  }
}

.lagebericht-item--content {
  @media all and (min-width: 1440px) {
    display: flex;
    flex-direction: row;
  }

  .date {
    @include date();
    display: block;
    margin-right: 25px;
    line-height: 1.38;
    @media all and (max-width: 1439px) {
      margin-bottom: 10px;
    }
  }

  p {
    margin: 0;
    @include fontrb(36, 400);
    line-height: 1.38;
    color: #000;
  }
}
