* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: 0px;
  padding: 0px;
  min-height: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

#wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex: 3;
  flex-direction: column;
}