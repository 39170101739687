.text-content {
  h1, h2, h3, h4, h5, h6 {
    @include fontrb(85, 400);
    color: #000;
    line-height: 1.41;
  }

  h2 {
    @include fs(75);
  }

  h3 {
    @include fs(65);
  }

  h4 {
    @include fs(55);
  }

  h5 {
    @include fs(45);
  }

  h6 {
    @include fs(35);
  }

  p {
    @include fontrb(38, 300);
    color: #000;
    line-height: 1.57;
    margin-bottom: 60px;
  }

  ul, ol {
    @include fontrb(38, 300);
    color: #000;
    line-height: 1.57;
    margin-bottom: 60px;
  }

  blockquote {
    font-family: 'Verdana', sans-serif;
    font-style: italic;
    @include fs(38);
    color: #000;
    line-height: 1.57;
    margin-bottom: 60px;
    margin-left: 0;
    padding: 15px 0 15px 40px;
    border-left: 4px solid $lblue;
  }
}