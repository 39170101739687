// top-questions styles
.top-question-sidebar {
  .top-question--article {
    background: #fff;
    padding: 22px between(0px, 100px, 0px, 3840px);
    border-bottom: 1px solid #d5d5d5;


    .top-question--content-container {
      padding-left: 35px;
    }

  }
}

.question-article {
  @include fontrb(30, 300);
  line-height: 1.4;
  cursor: pointer;

  .question {
    color: $dblue;
    transition: color .3s ease;
    margin-bottom: 24px;
    position: relative;

    &:before {
      content: '';
      @include position(absolute, 10px null null -36px);
      @include size(19px);
      border-radius: 50%;

      background: url('../images/ico_arrow-right.png') no-repeat center center;
      background-size: 15px;
      transform: translate3d(0, -50%, 0) rotate(0);
      border: 1px solid $dblue;
      transition: border-color .25s, transform .25s;
    }
  }

  .answer {
    color: #000;
    padding-left: 27px;
    border-left: 2px solid $lblue;
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
  }

  &.is-opened {
    .question {
      color: $lblue;
      &:before {
        background-image: url('../images/ico_arrow-right_lb.png');
        border-color: $lblue;
        transform: translate3d(0, -50%, 0) rotate(90deg);
      }
    }
  }

  &:hover {
    .question {
      color: $lblue;
      &:before {
        background-image: url('../images/ico_arrow-right_lb.png');
        border-color: $lblue;
      }
    }
  }
}

.sidebar-title {
  padding-bottom: 0;
  h2 {
    @include fontrb(36, 500);
    color: #000;
  }
}