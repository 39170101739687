// playbutton styles

.playbutton {
  overflow: hidden;
  text-indent: -999px;
  background: url('../images/ico_playbutton.png') no-repeat center center;
  background-size: 26px 41px;
  border: 0;
  &:active, &:focus {
    outline: none;
  }
  cursor: pointer;
  background-color: rgba(255,255,255,.55);
  @include size(104px);
  border-radius: 50%;
  &:hover {
    background: url('../images/ico_playbutton_yellow.png') no-repeat center center;
    background-color: rgba(255,255,255,.75);
  }

  &.absolute {
    position: absolute;
  }

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}