@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?59524719');
  src: url('../fonts/fontello.eot?59524719#iefix') format('embedded-opentype'),
  url('../fonts/fontello.woff2?59524719') format('woff2'),
  url('../fonts/fontello.woff?59524719') format('woff'),
  url('../fonts/fontello.ttf?59524719') format('truetype'),
  url('../fonts/fontello.svg?59524719#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?59524719#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.icon-search:before { content: '\e802'; } /* 'î ‚' */
.icon-angle-left:before { content: '\f104'; } /* 'ď„„' */
.icon-angle-right:before { content: '\f105'; } /* 'ď„…' */