// sidebar-news styles

.sidebar-news--container {
  overflow: hidden;
}

.sidebar-news--article {
  background: #fff;
  transition: background .25s ease;
  padding: 22px between(0px, 100px, 0px, 3840px);
  border-bottom: 1px solid #d5d5d5;

  &:hover, &.is-active {
    background: #f6f6f6;
  }
}

.home .sidebar-news--article--content {
  -webkit-backface-visibility: hidden;
  transform: translate3d(0,0,0);
  will-change: opacity;
  opacity: 0;
  transition: opacity .7s;
  &.start-animation {
    opacity: 1;
  }
}

.ajax-article--wrap {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: 2;
  background: #f6f6f6;
  transform: translate3d(-100%, 0, 0);
  backface-visibility: hidden;
  transition: transform 1s ease;

  &.is-opened {
    transform: translate3d(0,0,0);
  }

  .col-left,
  .col-right {
    width: 50%;
    float: left;
  }

  .col-left {
    padding-right: between(0, 90px, 0, 3840px);

    * {
      opacity: 0;
      transform: translate3d(-20px, 0, 0);
      transition: transform .7s, opacity .7s;
      transition-delay: .4s;
    }

    p, blockquote, ul, img, h2, h3, h4, h5, h6 {
      transition-delay: .8s;
    }
  }

  figure {
    margin: 0;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: auto;
    }
  }

  .col-right figure {
    backface-visibility: hidden;
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    transition: transform .7s, opacity .7s;
    transition-delay: 1.6s;
    &:after {
      display: block;
      content: '';
      @include position(absolute, 0 0 null null);
      @include size(100%);
      background: #f6f6f6;
      z-index: 1;
      height: 100%;
      transition: height .7s;
      transition-delay: 1.6s;
    }
  }

  header {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    button {
      border: none;
      background: transparent;
      @include fontrb(30, 400);
      width: 185px;
      text-align: center;
      line-height: 14px;
      padding: 13px 0 15px;
      color: #8c8c8c;
      cursor: pointer;
      transition: color .3s, background .3s;
      border-radius: 23px;
      &:focus, &:active {
        outline: none;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        &.close-button {
          &::before, &::after {
            background: #8c8c8c;
            transition: background .3s;
          }
          &:hover {
            &::before, &::after {
              background: #fff;
            }
          }
        }

        &.font-icon {
          @include fs(38);
          display: inline-block;
          @include size(22px);
          line-height: 16px;
          border: 2px solid #8c8c8c;
          border-radius: 50%;
          transition: border-color .3s;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        transform: translateX(0);
        transition: transform .25s;
        padding-left: 12px;
        &.reverse {
          padding-left: 0;
          padding-right: 12px;
        }
      }

      &:hover {
        background: $yellow;
        color: #fff;
        .close-button::before,
        .close-button::after {
          background: #fff;
        }

        .font-icon {
          border-color: #fff;
        }

        span {
          transform: translateX(3px);
          &.reverse {
            transform: translateX(-3px);
          }
        }
      }
    }

    .close-article {
      float: left;
      transform: translate3d(0,0,0);
      opacity: 0;
      transition: opacity .4s ease;
      transition-delay: 1.6s;
    }

    .article-navigation {
      float: right;
      transform: translate3d(0,0,0);
      opacity: 0;
      transition: opacity .4s ease;
      transition-delay: 1.8s;
      button {
        margin: 0 20px;
      }
    }
  }

  &.start-animation {
    .col-left {
      * {
        opacity: 1;
        transform: translate3d(0px, 0, 0);
      }
    }
    .col-right figure {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      &:after {
        height: 0;
      }
    }

    .close-article,
    .article-navigation {
      opacity: 1;
    }

  }
}

.ajax-article--content {
  overflow: hidden;
  padding-left: between(0, 100px, 0, 3840px);
  opacity: 1;
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
  transition: opacity .5s ease;
  .news-article {
    overflow: hidden;
  }
  &.is-hidden {
    opacity: 0;
  }
}


// General news styles
.news--article {
  .date {
    @include date();
  }
  h2 {
    @include fontrb(36, 400);
    line-height: 1.38;
    color: #000;
  }

  p {
    @include fontrb(30, 300);
    line-height: 1.4;
    color: #000;
  }

  .read-more {
    @include fontrb(28, 400);
    color: $dblue;
    text-decoration: none;
    position: relative;
    padding-left: 35px;
    &:before {
      content: '';
      @include position(absolute, 50% null null 0);
      @include size(19px);
      border-radius: 50%;
      background: url('../images/ico_arrow-right.png') no-repeat center center;
      background-size: 15px;
      transform: translate3d(0, -50%, 0);
      border: 1px solid transparent;
      transition: border-color .25s, transform .25s;
    }
    &:hover:before {
      border-color: $dblue;
      transform: translate3d(5px, -50%, 0);
    }
  }
}