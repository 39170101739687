.content-wrap {
  display: flex;
  flex: 1 1 0%;
  min-height: 0;
}

.scroll-container {
  overflow: hidden;
}

.sidebar {
  flex: 0 0 18.5vw;
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 300px;
  overflow: hidden;
  position: relative;

  .sidebar-content {
    flex-grow: 1;
  }
  .news-link {
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 10px;
    a {
      display: block;
      width: 100%;
      text-decoration: none;
      @include fontrb(36, 500);
      color: $lblue;
      transition: color .25s;
      text-align: center;
      padding: 20px 0 22px;
    }

  }
}

.content {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #f6f6f6;
  padding: 20px 30px 0;
  &.white {
    background-color: #fff;
  }
}

.no-right-padding {
  padding-right: 0;
}

.content-content {
  height: 100%;
}

.inside-content {
  min-height: 700px;
}

.home {
  .sidebar {
    transform: translate3d(-100%, 0, 0);
    transition: transform 1s ease;
    &.start-animation {
      transform: translate3d(0,0,0);
    }

    .news-link {
      -webkit-backface-visibility: hidden;
      transform: translate3d(0,0,0);
      will-change: opacity;
      opacity: 0;
      transition: opacity .7s;
      &.start-animation {
        opacity: 1;
      }
    }
  }
}

.loader {
  @include position(fixed, 0 0 null null);
  @include size(100%);
  z-index: 2;
  background: #f6f6f6;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;
  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0s 0.5s;
  }
}