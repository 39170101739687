// header styles
.main-header {
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
  padding-top: 35px;
  padding-bottom: 32px;
  overflow: visible;
  max-height: 140px;
  background-color: #fff;

  .container {
    overflow: hidden;
  }
}

.main-logo--container {
  float: left;
  margin-left: 1.9vw;
  width: 270px;
}

.main-logo {
  max-width: 214px;
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.home {
  .main-header {
    transform: translate3d(-100%, 0, 0);
    transition: transform 1s ease;
    &.start-animation {
      transform: translate3d(0,0,0);
    }
  }

  .main-logo--container {
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    will-change: opacity;
    opacity: 0;
    transition: opacity .7s;
    &.start-animation {
      opacity: 1;
    }
  }
}