// greeting styles
.greeting {
  width: 15.9vw;
  float: left;
  margin-top: 5px;
  min-width: 300px;
}

.grt {
  @include fontrb(40, 700);
  .grt-name {
    font-weight: 400;
  }
}

.msg-info--container {
  width: 100%;
  position: relative;
  margin-top: 5px;
  a {
    display: inline-block;
    position: relative;
    background: url('../images/icon-msg.png') no-repeat left center;
    background-size: 30px 20px;
    padding: 21px 0 21px 50px;
    color: $dblue;
    text-decoration: none;
    @include fontrb(36, 300);
    &:hover {
      text-decoration: underline;
    }
  }
  i {
    @include position(absolute, 0 null null 17px);
    display: block;
    @include fontrb(30, 700);
    color: #fff;
    z-index: 1;

    @include size(32px);
    line-height: 32px;
    text-align: center;
    font-style: normal;

    &:before {
      @include position(absolute, 0 0 0 0);
      border-radius: 50%;
      background: $yellow;
      filter: blur(1px);
      content: '';
      z-index: -1;
    }
  }

}

.home {
  .greeting {
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    will-change: opacity;
    opacity: 0;
    transition: opacity .7s;
    &.start-animation {
      opacity: 1;
    }
  }
}