.pageload-overlay {
  @include size(100%);
  @include position(fixed, 0 null null 0);
  visibility: hidden;
  z-index: 2;
}

.pageload-overlay.show {
  visibility: visible;
}

.pageload-overlay svg {
  @include position(absolute, 0 null null 0);
  pointer-events: none;
}

.pageload-overlay svg path {
  fill: #fff;
}

.pageload-overlay::after,
.pageload-overlay::before {
  content: '';
  @include position(fixed, 50% null null 50%);
  @include size(20px);;
  margin: -10px 0 0 -10px;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
  transition: opacity 0.15s, visibility 0s 0.15s;
}

.pageload-overlay::after {
  background: $dblue;
  transform: translateX(-20px);
  animation: moveRight 0.6s linear infinite alternate;
}

.pageload-overlay::before {
  background: $lblue;
  transform: translateX(20px);
  animation: moveLeft 0.6s linear infinite alternate;
}

@keyframes moveRight {
  to { transform: translateX(20px); }
}

@keyframes moveLeft {
  to { transform: translateX(-20px); }
}

.pageload-loading.pageload-overlay::after,
.pageload-loading.pageload-overlay::before {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}