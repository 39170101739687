// register styles
.register-wrap {
  @include size(100%);
  overflow: hidden;
  transform: translate3d(-100%, 0, 0);
  backface-visibility: hidden;
  @include position(absolute, 0 null null 0);
  z-index: 2;
  transition: transform .6s ease;
  &.is-visible {
    transform: translate3d(0, 0, 0);
  }

  .register-bg {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    background: $lblue;
    background: -moz-linear-gradient(top, $lblue 0%, $dblue 100%);
    background: -webkit-linear-gradient(top, $lblue 0%,$dblue 100%);
    background: linear-gradient(to bottom, $lblue 0%,$dblue 100%);
    opacity: .95;
  }

  .light {
    @include size(40vw 300px);
    @include position(absolute, 0 null null 50%);
    background: url('../images/light.png') no-repeat center top;
    background-size: 100%;
    transform: translate3d(-50%, -100%, 0);
    transform-origin: center top;
    transition: transform 1s ease 2.5s;
  }
}

.register--content-wrap {
  @include size(100% auto);
  min-height: 100%;
  overflow: hidden;
  max-width: 590px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  padding-top: 10vh;
  text-align: center;
  .icon-plus {
    @include size(78px);
    background: url('../images/icon-plus.png') no-repeat center center;
    background-size: 100%;
    @include position(absolute, 10vh null null 50%);
    transform: translate3d(-50%, 30px, 0);
    opacity: 0;
    transition: opacity .5s ease, transform .5s ease 1s;
  }


  h1 {
    @include fontrb(110, 300);
    color: #fff;
    margin-top: 100px;
    margin-bottom: 20px;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity .5s ease 1s, transform .5s ease 1s;
  }

  .backToLogin {
    @include fontrb(36, 300);
    color: #00ffea;
    text-decoration: none;
    line-height: 23px;
    display: block;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity .5s ease 1s, transform .5s ease 1s;
    span {
      vertical-align: middle;
    }
    .arrow-left {
      @include size(23px);
      vertical-align: middle;
      position: relative;
      border-radius: 50%;
      border: 1px solid #00ffea;
      display: inline-block;
      transform: translateX(0);
      transition: transform .3s ease;
      svg {
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
        @include size(80%);
        fill: #00ffea;
      }
    }

    &:hover {
      .arrow-left {
        transform: translateX(-5px);
      }
    }
  }

  .bottom-info {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
    @include fontrb(27, 400);
    color: #fff;
    line-height: 1.74;
    margin-top: 14vh;
    margin-bottom: 30px;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity .5s ease 2s, transform .5s ease 2s;
  }
}

.form-section-container {
  position: relative;
  width: 100%;
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  transition: opacity .5s ease 1.5s, transform .5s ease 1.5s;

  section {
    @include position(absolute, 0 5px null 5px);
    transition: transform .35s ease, opacity .35s;
    opacity: 0;
    backface-visibility: hidden;
    &:first-child {
      transform: translate3d(-100%, 0, 0);
    }
    &:last-child {
      transform: translate3d(100%, 0, 0);
    }
    &.active {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.register-form {
  margin-top: 8.9vh;
  padding: 0 5px;
  .input-wrap {
    position: relative;
    overflow: visible;
    margin-bottom: 14px;
    width: 100%;
    opacity: .2;
    transition: opacity .25s ease;
    label {
      @include position(absolute, 50% null null 35px);
      @include fontrb(38, 300);
      color: #6d6d6d;
      z-index: 2;
      transform: translateY(-50%);
    }

    &.show,
    &.show-always {
      opacity: 1;
    }
  }

  section input {
    width: 100%;
    border-radius: 63px;
    background: #fff;
    @include fontrb(48, 300);
    color: #000;
    text-align: center;
    padding: 22px 0 23px;
    line-height: 1;
    border: none;

    &:focus, &:active {
      outline: none;
    }

    &.parsley-error {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
      +label {
        color: red;
      }
    }
  }

  .button-wrap {
    position: relative;
    overflow: hidden;
    margin-top: 31px;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity .5s ease 1.7s, transform .5s ease 1.7s;
    input {
      width: 100%;
      border-radius: 63px;
      border: 0;
      background: #0d92d3;
      @include fontrb(38, 500);
      color: #fff;
      text-align: center;
      padding: 22px 0 23px;
      cursor: pointer;
      transition: background .3s ease, opacity .25s;
      &:focus, &:active {
        outline: none;
      }
      &:hover {
        background: $dblue;
      }

      &.inactive {
        pointer-events: none;
        cursor: default;
        opacity: .2;
      }

      &.is-hidden {
        display: none;
      }
    }
  }

  .info {
    @include fontrb(30, 300);
    color: #fff;
    text-align: left;
    margin-top: 15px;
    padding-left: 10px;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: opacity .5s ease 1.8s, transform .5s ease 1.8s;
  }

  .parsley-errors-list {
    display: none;
  }
}

.register--section-nav {
  width: auto;
  margin: 48px auto;
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  transition: opacity .5s ease 1.9s, transform .5s ease 1.9s;
  > a {
    text-decoration: none;
    display: inline-block;
    margin: 0 12px;
    transform: translateX(0);
    transition: transform .3s ease;
    svg {
      @include size(auto 14px);
      fill: #fff;
    }
    &.inactive {
      opacity: .2;
      cursor: default;
      pointer-events: none;
    }
    &:first-child:not(.inactive):hover {
      transform: translateX(-3px);
    }
    &:last-child:not(.inactive):hover {
      transform: translateX(3px);
    }
  }

  .section-bullet {
    padding: 0;
    display: inline-block;
    list-style: none;
    li {
      display: inline-block;
      @include size(15px);
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
      margin: 0 12px;
      transition: background .3s ease;
      &:hover {
        background: lighten($yellow, 15%);
      }
      &.active {
        background: $yellow;
      }
    }
  }

  &.disabled {
    opacity: .2;
    pointer-events: none;
  }
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.start-animation {
  .icon-plus {
    opacity: 1;
    transform: translate3d(-50%, 0px, 0);
  }
  .register--content-wrap h1,
  .register--content-wrap .backToLogin,
  .form-section-container,
  .button-wrap,
  .register--section-nav,
  .info,
  .bottom-info {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }

  .light {
    transform: translate3d(-50%, 0, 0);
  }

}