// footer styles
.main-footer {
  width: 100%;
  height: 68px;
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 7px solid #ececec;

  p {
    @include fontrb(25, 400);
    color: #000;
    @include position(absolute, 50% null null 20px);
    transform: translateY(-50%);
    margin: 0;
  }
}

.no-right-padding .main-footer {
  width: calc(100% - 30px);
}

.logo-footer {
  @include position(absolute, 50% 20px null null);
  @include size(130 41px);
  transform: translateY(-50%);
  background: url('../images/logo_footer.png') no-repeat center center;
  background-size: 100%;
}

.home {
  .main-footer {
    -webkit-backface-visibility: hidden;
    transform: translate3d(0,0,0);
    will-change: opacity;
    opacity: 0;
    transition: opacity .7s;
    &.start-animation {
      opacity: 1;
    }
  }
}