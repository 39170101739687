// Grid Settings

$neat-grid: (
  columns: 20,
  gutter: 46px,
);

// Colors
$lblue: #009fe3;
$dblue: #074690;
$yellow: #ffca2a;

// Breakpoints
