.container {
  position: relative;
  padding: 0 between(0px, 100px, 0px, 3840px) 0;
}

.grid {
  @include position(fixed, 0 0px null 0px);
  height: 100%;
  @include grid-visual;
  z-index: -1;
  display: none;
}