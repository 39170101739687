// navigation styles
.main-nav {
  float: right;
  .navigation {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      margin-left: 3px;
      border-radius: 23px;
      border: 1px solid #fff;
      transition: border-color .25s;
      a {
        display: block;
        padding: 13px 25px;
        color: #444444;
        @include fontrb(36, 400);
        text-decoration: none;
        transition: color .25s;
      }

      &:hover,
      &.current-menu-item {
        border-color: $dblue;
        a {
          color: $dblue;
          font-weight: 500;
        }
      }

      &.current-menu-item a {
        font-weight: 700;
      }
    }
  }
}

.home .main-nav {
  -webkit-backface-visibility: hidden;
  transform: translate3d(0,0,0);
  will-change: opacity;
  opacity: 0;
  transition: opacity .7s;
  &.start-animation {
    opacity: 1;
  }
}