/* Close button */

.close-button {
  position: relative;
  @include size(35px 37.5px);
  overflow: hidden;
  &:hover {
    &::before, &::after {
      background: $lblue;
    }
  }

  &.close-button-small {
    @include size(14px);
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
  }
  &::before {
    transform: rotate(50deg);
  }
  &::after {
    transform: rotate(-50deg);
  }
  &.big {
    transform: scale(3);
  }
  &.hairline {
    &::before, &::after {
      height: 1px;
    }
  }
  &.thick {
    &::before, &::after {
      height: 4px;
      margin-top: -2px;
    }
  }
  &.black {
    &::before, &::after {
      height: 8px;
      margin-top: -4px;
    }
  }
  &.heavy {
    &::before, &::after {
      height: 12px;
      margin-top: -6px;
    }
  }
  &.pointy {
    &::Before, &::after {
      width: 200%;
      left: -50%;
    }
  }

}

/* Video container */
.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  iframe {
    @include position(absolute, 0 null null 0);
    @include size(100%);
  }
}

.gradient-button {
  @include fontrb(38, 500);
  margin-top: 25px;
  color: #fff;
  width: 100%;
  text-align: center;
  line-height: 20px;
  padding: 21px 0 22px;
  border: 0;
  border-radius: 63px;
  cursor: pointer;
  background: #064e98;
  background: -moz-linear-gradient(left, #064e98 0%, #0197db 100%);
  background: -webkit-linear-gradient(left, #064e98 0%,#0197db 100%);
  background: linear-gradient(to right, #064e98 0%,#0197db 100%);
  &:focus, &:active {
    outline: none;
  }

  &:hover {
    background: #f87f00;
    background: -moz-linear-gradient(left, #f87f00 0%, #ffca00 100%);
    background: -webkit-linear-gradient(left, #f87f00 0%,#ffca00 100%);
    background: linear-gradient(to right, #f87f00 0%,#ffca00 100%);
    -webkit-box-shadow: 0px 0px 12px 1px rgba(248,127,0,0.5);
    -moz-box-shadow: 0px 0px 12px 1px rgba(248,127,0,0.5);
    box-shadow: 0px 0px 12px 1px rgba(248,127,0,0.5);
  }
}