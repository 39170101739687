.login-wrap {
  @include size(100%);
  position: relative;
  background: #fff;
}

.login-bg--wrap {
  @include position(absolute, null null 0 0);
  @include size(100%);
  overflow: hidden;
}

.login-bg--mask {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  transform: translate3d(0,0,0);
  backface-visiblity: hidden;
  transition: height 1.2s cubic-bezier(.47,.29,.13,.97);
  z-index: 2;
  background: #fff;
  &.start-animation {
    height: 0;
  }
}

.login-bg {
  @include position(absolute, null null 0 0);
  @include size(100%);
  background: url('../images/bg_login.jpg') no-repeat center center;
  background-size: cover;
  z-index: 1;
  transform: scale3d(1.3, 1.3, 1);
  transform-origin: center center;
  backface-visiblity: hidden;
  transition: transform 1.2s ease .6s;
  &.start-animation {
    transform: scale3d(1, 1, 1);
  }
}